import { Container } from "typescript-ioc";
import { FormFactory } from "./FormFactory";

import "../Styles/form.css";

declare global {
    interface IOri {
        formFactory?: FormFactory;
    }
}

ori.formFactory = Container.get(FormFactory);
