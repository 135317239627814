import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { Form } from "./Form";
import { IFormOptions } from "./IFormOptions";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponentFactory } from "./UiComponentFactory";

@OnlyInstantiableByContainer
@Singleton
export class FormFactory {
    public get key(): string {
        return "FormFactory";
    }

    private readonly _uiComponentFactory: UiComponentFactory;
    private readonly _logger: ILogger;

    constructor(
        @Inject uiComponentFactory: UiComponentFactory,
        @Inject loggerFactory: LoggerFactory
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._uiComponentFactory = uiComponentFactory;
    }

    /**
     * Creates new Form instance based on selector and options.
     * @param selector Selector string which will be the slider context.
     * @param options Optional settings for created Form instance.
     * @returns New instance of Form.
     */
    public create(selector: Element | string, options?: IFormOptions): Form | null {
        if (!selector) {
            this._logger.error("No selector or element provided.");

            return null;
        }

        return this._uiComponentFactory.createBase(Form, selector, options);
    }
}
